import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Error404 from "./components/Error404/Error404";
import { Helmet } from "react-helmet-async";
import useTelegramWebApp from "./hooks/useTelegramWebApp";
import { TelegramWebAppContext } from "./services/telegram";
import useEruda from "./hooks/useEruda";
import { useMobileHandler } from "./hooks/useMobileHandler";
import { useEffect, Suspense } from "react";
import uaParserJs from "ua-parser-js";
import { setIsIOS } from "./store/reducers/mainSlice";
import { useDispatch } from "react-redux";
import { routes } from "./config/routes";
import { LazyDrawItemPage, LazyDrawPage, LazyGamePlayPage, LazyHomepage } from "./pages/LazyPages";
import GlobalLoader from "./components/GlobalLoader/GlobalLoader";
import Layout from "./components/Layout/Layout";

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Error404 />,
    children: [
      {
        path: routes.homePage.path,
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <LazyHomepage />
          </Suspense>
        ),
      },
      {
        path: routes.drawPage.path,
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <LazyDrawPage />
          </Suspense>
        ),
      },
      {
        path: routes.gamePlayPage.path,
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <LazyGamePlayPage />
          </Suspense>
        ),
      },
      {
        path: routes.drawItemPage.path,
        element: (
          <Suspense fallback={<GlobalLoader />}>
            <LazyDrawItemPage />
          </Suspense>
        ),
      },
    ],
  },
]);

const App = () => {
  const dispatch = useDispatch();
  const telegramValues = useTelegramWebApp();
  useMobileHandler();
  useEruda();
  useEffect(() => {
    const userAgent = uaParserJs(window.navigator.userAgent);
    const isIOS = userAgent?.os.name === "iOS";
    dispatch(setIsIOS(isIOS));
  }, [dispatch]);

  return (
    <TelegramWebAppContext.Provider value={telegramValues}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Tektur:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Purimi Connect" />
      </Helmet>
      <RouterProvider router={router} />
    </TelegramWebAppContext.Provider>
  );
};

export default App;
