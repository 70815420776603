import { useEffect, useState } from "react";

const PreventPullToRefresh = ({ children }) => {
  const [lastTouchEnd, setLastTouchEnd] = useState(0);
  useEffect(() => {
    const disableDoubleTouch = e => {
      let now = new Date().getTime();

      if (now - lastTouchEnd <= 300) {
        e.preventDefault();
      }

      setLastTouchEnd(now);
    };

    const disableMultiTouch = e => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    const disablePullToRefresh = e => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    const disableGesturestart = e => {
      e.preventDefault();
    };

    const disableGestureChange = e => {
      e.preventDefault();
    };

    const disableGestureEnd = e => {
      e.preventDefault();
    };

    document.addEventListener("touchmove", disablePullToRefresh, { passive: false });
    document.addEventListener("touchstart", disableMultiTouch, { passive: false });
    document.addEventListener("touchend", disableDoubleTouch, false);
    document.addEventListener("gesturestart", disableGesturestart);
    document.addEventListener("gesturechange", disableGestureChange);
    document.addEventListener("gestureend", disableGestureEnd);

    return () => {
      document.removeEventListener("touchmove", disablePullToRefresh);
      document.removeEventListener("touchstart", disableMultiTouch);
      document.removeEventListener("touchend", disableDoubleTouch);
      document.removeEventListener("gesturestart", disableGesturestart);
      document.removeEventListener("gesturechange", disableGestureChange);
      document.removeEventListener("gestureend", disableGestureEnd);
    };
  }, [lastTouchEnd]);

  return <div style={{ touchAction: "pan-x", height: "100%" }}>{children}</div>;
};

export default PreventPullToRefresh;
