import { Outlet } from "react-router-dom";
import useBackButton from "../../hooks/useBackButton";
import PreventPullToRefresh from "../PreventPullToRefresh/PreventPullToRefresh";
import styles from "./Layout.module.scss";

const Layout = () => {
  useBackButton();

  return (
    <div className={`App ${styles.layoutContainer}`}>
      <PreventPullToRefresh>
        <main>
          <Outlet />
        </main>
      </PreventPullToRefresh>
    </div>
  );
};

export default Layout;
