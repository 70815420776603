import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTelegramWebAppContext } from "./useTelegramWebAppContext";

function useBackButton() {
  const [backButton, setBackButton] = useState(null);

  const { tg, isReady, onBackButtonClick, offBackButtonClick, toggleBackButton } =
    useTelegramWebAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isReady) {
      setBackButton(tg.BackButton);

      if (location.pathname === "/") {
        toggleBackButton(false);
      } else toggleBackButton(true);
    }

    const handleBackButton = () => {
      navigate(-1);
    };

    onBackButtonClick(handleBackButton);

    return () => {
      offBackButtonClick(handleBackButton);
    };
  }, [
    isReady,
    location.pathname,
    navigate,
    offBackButtonClick,
    onBackButtonClick,
    tg,
    toggleBackButton,
  ]);

  return backButton;
}

export default useBackButton;
