export const routes = {
  homePage: {
    path: "/",
  },
  drawPage: {
    path: "draw",
  },
  gamePlayPage: {
    path: "game",
  },
  drawItemPage: {
    path: "draw/:id",
  },
};

export const mockDrawsList = [
  { id: 1, title: "First" },
  { id: 2, title: "Second" },
  { id: 3, title: "Third" },
];
