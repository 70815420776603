export const API_ROUTES = {
  getPlayer: "/player/",
  sendPlayerWallet: "/player/wallet/",
};
export const API_URL = process.env.API_URL;
export const APP_LINK = process.env.APP_LINK;
export const ENV = process.env.ENV;
export const IS_DEBUG_MODE_ENABLED = process.env.IS_DEBUG_MODE_ENABLED === "true";
export const MANIFEST_URL =
  (ENV === "local" ? "http://localhost:3000" : APP_LINK) + "/tonconnect-manifest.json";
export const SENTRY_DSN = process.env.SENTRY_DSN;
