import { useEffect } from "react";
import { IS_DEBUG_MODE_ENABLED } from "../config/api";

const useEruda = () => {
  useEffect(() => {
    if (IS_DEBUG_MODE_ENABLED) {
      import("eruda").then(lib => lib.default.init());
    }
  }, []);
};

export default useEruda;
