import React from "react";

const Error404 = () => {
  return (
    <div>
      <h1>404: Not Found</h1>
    </div>
  );
};

export default Error404;
