import { useEffect, useState } from "react";

const useTelegramWebApp = () => {
  const [tg, setTg] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const telegramWebApp = window.Telegram.WebApp;
      telegramWebApp.ready();
      setTg(telegramWebApp);
      telegramWebApp.expand();
      setIsReady(true);
      telegramWebApp.disableVerticalSwipes();
    }
  }, []);

  const onBackButtonClick = callback => {
    if (tg) {
      tg.onEvent("backButtonClicked", callback);
    }
  };

  const offBackButtonClick = callback => {
    if (tg) {
      tg.offEvent("backButtonClicked", callback);
    }
  };

  const toggleBackButton = show => {
    if (tg) {
      if (show) {
        tg.BackButton.show();
      } else {
        tg.BackButton.hide();
      }
    }
  };

  return {
    tg,
    isReady,
    onBackButtonClick,
    offBackButtonClick,
    toggleBackButton,
  };
};

export default useTelegramWebApp;
