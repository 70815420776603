import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMobile } from "../store/reducers/mainSlice";
import { BREAKPOINTS } from "../styles/variables/breakpoints";

export const useMobileHandler = () => {
  const dispatch = useDispatch();

  const handleMobileChange = useCallback(
    e => {
      dispatch(setMobile(e.matches));
    },
    [dispatch],
  );

  const handleMobileDetection = useCallback(() => {
    const phoneMediaQuery = `(max-width: ${BREAKPOINTS.mobile}px)`;
    const mql = window.matchMedia(phoneMediaQuery);
    dispatch(setMobile(mql.matches));

    try {
      mql.addEventListener("change", handleMobileChange);
    } catch {
      // Safari
      mql.addListener(handleMobileChange);
    }

    return () => {
      try {
        mql.removeEventListener("change", handleMobileChange);
      } catch {
        // Safari
        mql.removeListener(handleMobileChange);
      }
    };
  }, [dispatch, handleMobileChange]);

  useEffect(handleMobileDetection, [handleMobileDetection]);
};
